import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconGiftCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 60 60" {...props}>
			<path
				fill="#E6F2F3"
				d="M36.3 7.5a6.2 6.2 0 1 1 0 12.5h8.2c2.8 0 4.2 0 5.3.5a5 5 0 0 1 2.2 2.2c.5 1.1.5 2.5.5 5.3v14c0 2.8 0 4.2-.5 5.3a5 5 0 0 1-2.2 2.2c-1.1.5-2.5.5-5.3.5h-29c-2.8 0-4.2 0-5.3-.5A5 5 0 0 1 8 47.3c-.5-1.1-.5-2.5-.5-5.3V28c0-2.8 0-4.2.5-5.3a5 5 0 0 1 2.2-2.2c1.1-.5 2.5-.5 5.3-.5h8.3a6.2 6.2 0 1 1 6.2-6.3c0-3.4 2.8-6.2 6.3-6.2Z"
			/>
			<path
				stroke="#027F88"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M30 13.8V20m0-6.3a6.2 6.2 0 1 1 6.3 6.3M30 13.7a6.2 6.2 0 1 0-6.3 6.3m6.3 0h6.3M30 20h-6.3m6.3 0v15m6.3-15h8.2c2.8 0 4.2 0 5.3.5a5 5 0 0 1 2.2 2.2c.5 1.1.5 2.5.5 5.3v7M23.7 20h-8.2c-2.8 0-4.2 0-5.3.5A5 5 0 0 0 8 22.7c-.5 1.1-.5 2.5-.5 5.3v7m0 0v7c0 2.8 0 4.2.5 5.3a5 5 0 0 0 2.2 2.2c1.1.5 2.5.5 5.3.5H30M7.5 35H30m0 0v15m0-15h22.5M30 50h14.5c2.8 0 4.2 0 5.3-.5a5 5 0 0 0 2.2-2.2c.5-1.1.5-2.5.5-5.3v-7"
			/>
		</Icon>
	);
}
