import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconMailOpenCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 61 60" {...props}>
			<path
				fill="#E6F2F3"
				d="m36.2 11.4 14 11.2c1 .9 1.6 1.3 2 1.9.4.5.6 1 .8 1.6.2.6.2 1.3.2 2.7v15.7c0 2.8 0 4.2-.6 5.3a5 5 0 0 1-2.2 2.2c-1 .5-2.4.5-5.2.5h-29c-2.8 0-4.2 0-5.3-.5a5 5 0 0 1-2.2-2.2c-.5-1.1-.5-2.5-.5-5.3V29c0-1.5 0-2.2.2-2.9a5 5 0 0 1 .8-1.6c.4-.6 1-1 2.1-1.9l15-11.3C28.1 9.9 29 9.3 30 9a5 5 0 0 1 2.6 0c1 .3 1.8 1 3.6 2.4Z"
			/>
			<path
				stroke="#027F88"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m10.7 25 15.2 11.5h0c1.7 1.3 2.6 1.9 3.5 2.1a5 5 0 0 0 2.5 0c1-.2 1.8-.8 3.5-2L50.7 25m-.5-2.4-14-11.2C34.4 10 33.6 9.3 32.6 9A5 5 0 0 0 30 9c-1 .2-1.9 1-3.7 2.3l-15 11.3c-1.1.9-1.7 1.3-2.1 1.9a5 5 0 0 0-.8 1.6c-.2.7-.2 1.4-.2 2.9v15.5c0 2.8 0 4.2.5 5.3A5 5 0 0 0 11 52c1 .5 2.5.5 5.3.5h29c2.8 0 4.2 0 5.2-.5a5 5 0 0 0 2.2-2.2c.6-1.1.6-2.5.6-5.3V28.8c0-1.4 0-2-.2-2.7a5 5 0 0 0-.8-1.6c-.4-.6-1-1-2-1.9Z"
			/>
		</Icon>
	);
}
